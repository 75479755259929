import { mapState } from 'vuex'
import { en, ru } from 'vuejs-datepicker/dist/locale'

export default {
  data: () => ({
    en,
    ru,
  }),
  computed: {
    ...mapState('user', [
      'user',
    ]),

    lang () {
      return this.user.language === 'en'
        ? this.en
        : this.ru
    },
  },
}
