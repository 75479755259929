<template>
  <Datepicker
    :value="localValue"
    :language="lang"
    :disabled-dates="disabledDatesOn ? disabledDates : {}"
    :monday-first="firstDayIsMonday"
    :inline="inline"
    :highlighted="computedHighlighted"
    @input="$emit('input', $event)"
  />
</template>

<script>
import Datepicker from 'vuejs-datepicker';

import datepickerLangMixin from '@/mixins/datepickerLangMixin';
import datepickerDisabledDatesMixin from '@/mixins/datepickerDisabledDatesMixin';
import { firstDayIsMonday } from '@/mixins/user';

export default {
  name: 'VDatepicker',
  components: {
    Datepicker,
  },
  mixins: [
    datepickerLangMixin,
    datepickerDisabledDatesMixin,
    firstDayIsMonday,
  ],

  props: {
    value: [Number, Date],
    inline: {
      type: Boolean,
      default: true,
    },
    disabledDatesOn: {
      type: Boolean,
      default: true,
    },
    highlighted: Object,
  },
  computed: {
    localValue() {
      // если даты нет, возвращаем `null` вместо `0`
      // чтобы календарь показывал текущий месяц без выбранной даты
      return this.value || null;
    },
    /**
     * Для того чтобы включать дату окончания в диапазон
     */
    computedHighlighted() {
      return this.highlighted?.from && this.highlighted?.to
        ? {
          ...this.highlighted,
          to: this.highlighted.to.add(1, 'day'),
        }
        : undefined;
    }
  },
}
</script>

<!-- Эти стили без scoped, потому что темную тему не пробросить в scoped-deep -->
<style lang="scss">
.vdp-datepicker__calendar {
  width: 100%;
  max-width: 240px;
  // без !important не пофиксить чужие стили
  border: none !important;
  position: relative;

  header {
    line-height: 30px;

    span {
      font-size: rem(14px);

      &:after {
        width: 10px;
        height: 10px;
        border: none !important;
        background: url('/icons/arrow.svg') center center no-repeat;
        margin: 0 !important;
      }

      &.prev {
        transform: rotate(180deg);

        @include dark-theme {
          transform: rotate(180deg);
        }
      }

      // здесь такой сложный селектор, чтоб перебить базовый ховер библиотеки
      &:not(.disabled) {
        &.prev, &.up, &.next {
          &:hover {
            @include dark-theme {
              background: $darkThemeBgThree;
            }
          }
        }
      }

      @include dark-theme {
        font-size: rem(14px);

        &:hover {
          background: $darkThemeBgThree;
        }
      }
    }

    @include dark-theme {
      line-height: 30px;
    }
  }

  span.cell {
    $cellFontSize: 12px;
    font-size: $cellFontSize;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;

    &:not(.blank):not(.disabled).day:hover,
    &:not(.blank):not(.disabled).month:hover,
    &:not(.blank):not(.disabled).year:hover {
      border: 1px solid $blue;
    }

    &.today {
      color: $blue;
    }

    &.selected {
      background: $blue;
      color: #fff;
      font-size: $cellFontSize;
    }
  }

  @include dark-theme {
    background: $darkThemeBgTwo;
  }
}

</style>
