import { mapState } from 'vuex'
import dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const firstDayIsMonday = {
  computed: {
    ...mapState('user', [
      'user',
    ]),

    firstDayIsMonday () {
      return this.user.startOfWeek === 'Monday'
    },
  },
}

export const tasksBackground = {
  computed: {
    ...mapState('user', [
      'user',
    ]),

    tasksBackground () {
      return this.user.mainBackground
    },
  },
}

export const dateFormat = {
  computed: {
    ...mapState('user', [
      'user',
    ]),

    dateFormat () {
      return this.user.dateFormat
    },
  },
}

export const formatYYYY = {
  mixins: [
    dateFormat,
  ],

  methods: {
    /**
     * Если initialFormat не определен, date интерпритируем как timestamp.
     *
     * @param {timestamp|string} date
     * @param initialFormat
     * @return {string}
     */
    formatYYYY (date, initialFormat = null) {
      const storeFormat = this.dateFormat
      const formatYYYY = storeFormat.replace(/YY/gi, 'YYYY')

      return initialFormat
        ? dayjs(date, initialFormat).format(formatYYYY)
        : dayjs(date).format(formatYYYY)
    },
  },
}
